import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: 'rgba(26, 28, 26, 0.50)',
    backdropFilter: 'blur(3px)',
    zIndex: 110
  },
  dialogContainer: {
    zIndex: 120
  },
  dialog: {
    borderRadius: '8px',
    bg: `bg.1`,
    px: 0,
    py: '24px',
    height: 'fit-content',
    position: ['fixed', 'initial'],
    bottom: [0, 'unset'],
    top: ['unset', 'unset'],
    margin: [0, '8rem auto']
  },
  dialogHeader: {
    display: 'block'
  }
});

export default defineMultiStyleConfig({
  baseStyle
});
