const styles = {
  global: (props: any) => ({
    'html, body': {
      fontSize: 'sm',
      color: 'white',
      lineHeight: 'tall',
      background: 'black'
    }
  })
};

export default styles;
