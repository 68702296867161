import { CoreBlockchain, CoreBlockchainTestnet } from '@thirdweb-dev/chains';
import { config } from '@app/config';

export enum ModeEnvs {
  MAINNET = 'MAINNET',
  TESTNET = 'TESTNET'
}

export function getActiveChain() {
  switch (config.modeEnv) {
    case ModeEnvs.MAINNET: {
      return CoreBlockchain;
    }
    case ModeEnvs.TESTNET:
    default: {
      return CoreBlockchainTestnet;
    }
  }
}
