import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import {
  ThirdwebProvider,
  coinbaseWallet,
  metamaskWallet,
  rainbowWallet,
  walletConnect
} from '@thirdweb-dev/react';
import React from 'react';

import { config } from '@app/config';
import theme from '@app/theme';

import '@fontsource-variable/space-grotesk';
import '@fontsource-variable/raleway';
import '@fontsource-variable/inter';
import { getActiveChain } from '@app/constants/chains';

interface AppContextWrapperProps {
  children: React.ReactNode;
  query: { country: string; city: string; region: string };
}

const activeChain = getActiveChain();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const AppContextWrapper = ({ children, query }: AppContextWrapperProps) => {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ThirdwebProvider
          activeChain={activeChain}
          clientId={config.thirdwebClientId}
          supportedChains={[activeChain]}
          supportedWallets={[
            coinbaseWallet(),
            metamaskWallet(),
            rainbowWallet(),
            walletConnect()
          ]}
        >
          {children}
        </ThirdwebProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default AppContextWrapper;
