import { extendBaseTheme } from '@chakra-ui/react';

import colors from './colors';
import Modal from './components/modal';
import { Button } from './components/button';
import fonts from './fonts';
import styles from './styles';

const overrides = {
  styles,
  colors,
  fonts,
  components: {
    Modal,
    Button
  }
};

export default extendBaseTheme(overrides);
