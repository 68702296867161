const colors = {
  white: '#FCFCFC',
  gray: {
    0: '#DDE1DD',
    1: '#AAAFA9',
    2: '#747B73',
    3: '#575D56',
    4: '#3A3E3A',
    5: '#2B2E2A',

    6: '#202020',
    7: '#242424',
    8: '#1C1C1C',
    9: '#171717',
    10: '#222222',
    11: '#313131',
    12: '#2e2e2e',
    13: '#191919',
    14: '#909090'
  },
  bg: {
    1: '#121412',
    2: '#1A1C1A'
  },
  brandGradient: 'linear-gradient(180deg, #7EE575 0%, #42BA5D 100%)',
  gradientShade: '#4EA949',
  green: {
    1: '#7EE575',
    2: '#42BA5D'
  },
  system: {
    success: '#1AD37B',
    error: '#FF3636',
    warning: '#F9C91D',
    focused: '#AF60FF'
  },

  black: '#121212',
  orange: '#FF4500',
  success: '#2E9F30',
  error: '#FF4500',
  primaryWhite: '#EBEBEB',
  secondary: {
    darkOrange: '#330E00'
  },
  txt: {
    accent: '#FFAD8F'
  },
  danger: {
    200: '#F0C2BF',
    800: '#6D2723'
  },
  neutral: {
    100: '#E2E2E2',
    200: '#BCBCBC',
    300: '#969696',
    400: '#707070',
    500: '#494949',
    700: '#232323',
    800: '#1A1A1A',
    1000: '#131313'
  },
  blackOpacity: {
    96: 'rgba(18, 18, 18, 0.96)'
  },
  whiteOpacity: {
    4: 'rgba(255, 255, 255, 0.04)',
    6: 'rgba(255, 255, 255, 0.06)',
    10: 'rgba(255, 255, 255, 0.1)',
    12: 'rgba(255, 255, 255, 0.12)',
    50: 'rgba(255, 255, 255, 0.5)',
    70: 'rgba(255, 255, 255, 0.7)'
  }
};

export default colors;
