import { ToastContainer } from 'react-toastify';

import AppContextWrapper from '@app/context';

import type { AppProps, AppContext } from 'next/app';

import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import '@app/styles/index.scss';

interface Props extends AppProps {
  props: {
    query: { country: string; city: string; region: string };
  };
}

const App = ({ Component, pageProps, router, props }: Props) => {
  return (
    <AppContextWrapper {...props}>
      <Component {...pageProps} key={router.asPath} />
      <ToastContainer
        autoClose={10000}
        closeButton={false}
        theme="dark"
        hideProgressBar
      />
    </AppContextWrapper>
  );
};

App.getInitialProps = ({ ctx }: AppContext) => {
  return {
    props: {
      query: ctx.query
    }
  };
};

export default App;
