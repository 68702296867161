import { defineStyle } from '@chakra-ui/react';

const lg = defineStyle({
  height: '56px'
});
const md = defineStyle({
  height: '48px'
});
const sm = defineStyle({
  height: '40px'
});

export const Button = {
  baseStyle: {
    borderRadius: '8px',
    padding: '8px 24px',
    fontWeight: 600,
    lineHeight: '21px',
    fontSize: '14px',
    _disabled: {
      cursor: 'default',
      opacity: 0.5,
      pointerEvents: 'none'
    },
    _focusVisible: {
      outlineColor: 'system.focused',
      outlineWidth: '6px',
      outlineOffset: 0,
      outlineStyle: 'solid'
    }
  },
  sizes: { sm, md, lg },
  variants: {
    primary: {
      background: 'brandGradient',
      color: 'bg.1',
      _hover: {
        background: 'green.1'
      },
      _active: {
        background: 'green.1'
      }
    },
    secondary: {
      background: 'bg.2',
      color: 'green.2',
      _hover: {
        color: 'green.1',
        outline: '2px solid',
        outlineColor: 'green.1',
        outlineOffset: '-2px'
      },
      _active: {
        color: 'bg.1',
        background: 'green.1'
      }
    },
    tertiary: {
      background: 'bg.2',
      color: 'white',
      _hover: {
        color: 'green.1',
        outline: '2px solid',
        outlineColor: 'green.1',
        outlineOffset: '-2px'
      },
      _active: {
        color: 'bg.1',
        background: 'green.1'
      }
    },
    transparent: {
      background: 'transparent',
      color: 'white',
      _hover: {
        color: 'green.1'
      },
      _active: {
        color: 'bg.1',
        background: 'transparent'
      }
    },
    toggle: {
      background: '#171816',
      borderRadius: '2px',
      color: 'gray.1',
      _hover: {
        color: 'green.1'
      },
      _active: {
        color: 'bg.1',
        background: '#171816'
      }
    },
    error: {
      background: 'bg.2',
      color: 'white',
      _hover: {
        color: 'system.error',
        outline: '2px solid',
        outlineColor: 'system.error',
        outlineOffset: '-2px'
      },
      _active: {
        color: 'bg.1',
        background: 'bg.2'
      }
    }
  }
};
